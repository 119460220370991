import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { PreferenceSettings } from '../types';

const updatePreferences = async (preferencesToUpdate: PreferenceSettings): Promise<PreferenceSettings> => {
  const { data } = await axios.post('/preferences', {
    preferences: preferencesToUpdate,
  });
  return data.preferences;
};

export const useUpdatePreferences = (
  options?: UseMutationOptions<PreferenceSettings, unknown, PreferenceSettings, unknown>,
) => {
  const queryClient = useQueryClient();
  return useMutation<PreferenceSettings, unknown, PreferenceSettings, unknown>({
    ...options,
    mutationFn: updatePreferences,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({ queryKey: ['preferences'] });
    },
  });
};
