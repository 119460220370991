import { Grid, Title } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { ROUTES_TO_ROUTE_DETAILS } from '@routes/constants';
import { PRIVATE_ROUTES } from '@routes/enums';
import { getActiveLink } from '@utils/getActiveLink';

export const PageTitle = () => {
  const location = useLocation();

  const activeParent = getActiveLink(Object.values(PRIVATE_ROUTES), location.pathname);

  if (!activeParent) {
    throw Error('Parent route details does not exist.');
  }

  const routeDetails = ROUTES_TO_ROUTE_DETAILS[activeParent];
  const IconComponent = routeDetails.icon;
  return (
    <Grid gutter="xs">
      <Grid.Col span={{ base: 'content', xl: 12 }}>
        <IconComponent size="40px" stroke={2} />
      </Grid.Col>
      <Grid.Col span={{ base: 'content', xl: 12 }}>
        <Title>{routeDetails.title}</Title>
      </Grid.Col>
    </Grid>
  );
};
