import { Stack, Text } from '@mantine/core';

import { useFlexibleEvents } from '../api/getFlexibleEvents';
import { FlexibilityType } from '../types';

import { FlexibleEventItem } from './FlexibleEventItem';

interface FlexibleEventsListProps {
  flexibilityType: FlexibilityType | null;
}

export function FlexibleEventsList({ flexibilityType }: FlexibleEventsListProps) {
  const { data: events, isLoading } = useFlexibleEvents();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!events) {
    return null;
  }

  const filteredEvents = events.filter((event) => event.flexibilityType === flexibilityType);

  if (filteredEvents.length === 0) {
    return (
      <Text size="sm" c="dimmed" fs="italic">
        No potential events found on your calendar.
      </Text>
    );
  }

  return (
    <Stack gap="xs">
      {filteredEvents.map((event) => (
        <FlexibleEventItem key={event.recurringRemoteEventId} event={event} />
      ))}
    </Stack>
  );
}
