import type { DragUpdate, DropResult } from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, Group, LoadingOverlay, Paper, Stack, Text, Title } from '@mantine/core';
import { IconCornerDownRightDouble, IconReload, IconReplace } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { useFlexibleEvents } from '../api/getFlexibleEvents';
import { useMarkAsFlexible } from '../api/markAsFlexible';
import { useUnmarkAsFlexible } from '../api/unmarkAsFlexible';
import { FlexibilityType, FlexibleEvent } from '../types';

import { FlexibilityColumn } from './FlexibilityColumn';
import { FlexibleEventCard } from './FlexibleEventCard';

interface FrequencyColumn {
  id: string;
  title: string;
  events: FlexibleEvent[];
}

interface FlexibilityColumn {
  id: FlexibilityType;
  title: string;
  description: React.ReactNode;
  Icon: React.ElementType;
}

const FLEXIBILITY_COLUMNS: FlexibilityColumn[] = [
  {
    id: FlexibilityType.MOVABLE,
    title: 'Movable',
    description: (
      <>
        If Blockit can&apos;t find time on your calendar, it will <u>auto-reschedule</u> these meetings.
      </>
    ),
    Icon: IconReplace,
  },
  {
    id: FlexibilityType.SKIPPABLE,
    title: 'Skippable',
    description: (
      <>
        If Blockit can&apos;t find time on your calendar, it will schedule over these meetings (but will not move them).
      </>
    ),
    Icon: IconCornerDownRightDouble,
  },
];

export function FlexibleEventsKanban() {
  const queryClient = useQueryClient();
  const { data: flexibleEvents = [], isLoading } = useFlexibleEvents();
  const { mutate: markAsFlexible } = useMarkAsFlexible();
  const { mutate: unmarkAsFlexible } = useUnmarkAsFlexible();

  const unassignedEvents = flexibleEvents.filter((event) => !event.flexibilityType);

  const frequencyColumns: FrequencyColumn[] = [
    {
      id: 'frequency-weekly',
      title: 'Weekly or Daily',
      events: unassignedEvents.filter(
        (event) =>
          (event.recurrance.frequency === 'weekly' && event.recurrance.interval === 1) ||
          event.recurrance.frequency === 'daily',
      ),
    },
    {
      id: 'frequency-biweekly',
      title: 'Bi-Weekly',
      events: unassignedEvents.filter(
        (event) => event.recurrance.frequency === 'weekly' && event.recurrance.interval === 2,
      ),
    },
    {
      id: 'frequency-other',
      title: 'Less Frequent',
      events: unassignedEvents.filter((event) => !['daily', 'weekly'].includes(event.recurrance.frequency)),
    },
  ];

  /**
   * Update the flexibility type of an event in the local cache
   *
   * We add this so that we don't have a flicker when the event is updated.
   */
  const updateEventOptimistically = (eventId: string, flexibilityType: FlexibilityType | null) => {
    queryClient.setQueryData(['flexible-events'], (oldData: FlexibleEvent[] | undefined) => {
      if (!oldData) return [];

      return oldData.map((event) => (event.recurringRemoteEventId === eventId ? { ...event, flexibilityType } : event));
    });
  };

  const handleDragUpdate = (update: DragUpdate) => {
    const { destination, source, draggableId } = update;

    if (!destination) return;
    if (destination.droppableId === source.droppableId) return;

    const isToFlexibility = [FlexibilityType.MOVABLE, FlexibilityType.SKIPPABLE].includes(
      destination.droppableId as FlexibilityType,
    );
    const isToFrequency = destination.droppableId.startsWith('frequency-');

    // We update the event in the local cache so that we don't have a flicker when the drag is ended
    if (isToFlexibility) {
      updateEventOptimistically(draggableId, destination.droppableId as FlexibilityType);
    } else if (isToFrequency) {
      updateEventOptimistically(draggableId, null);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      // If dropped outside valid droppable, revert to original position
      const originalFlexibilityType = source.droppableId.startsWith('frequency-')
        ? null
        : (source.droppableId as FlexibilityType);
      updateEventOptimistically(draggableId, originalFlexibilityType);
      return;
    }

    const isFromFrequency = source.droppableId.startsWith('frequency-');
    const isToFrequency = destination.droppableId.startsWith('frequency-');
    const isToFlexibility = [FlexibilityType.MOVABLE, FlexibilityType.SKIPPABLE].includes(
      destination.droppableId as FlexibilityType,
    );

    // Don't allow moving between frequency columns
    if (isFromFrequency && isToFrequency) {
      updateEventOptimistically(draggableId, null);
      return;
    }

    // Moving from frequency to flexibility type
    if (isToFlexibility) {
      const newFlexibilityType = destination.droppableId as FlexibilityType;

      markAsFlexible(
        {
          recurringRemoteEventId: draggableId,
          flexibilityType: newFlexibilityType,
        },
        {
          onError: () => {
            updateEventOptimistically(draggableId, null);
          },
        },
      );
      return;
    }

    // Moving between flexibility types or back to frequency
    if (!isFromFrequency) {
      if (isToFrequency) {
        unmarkAsFlexible(
          { recurringRemoteEventId: draggableId },
          {
            onError: () => {
              updateEventOptimistically(draggableId, source.droppableId as FlexibilityType);
            },
          },
        );
      } else {
        // Case where we move between flexibility types
        const newFlexibilityType = destination.droppableId as FlexibilityType;

        markAsFlexible(
          {
            recurringRemoteEventId: draggableId,
            flexibilityType: newFlexibilityType,
          },
          {
            onError: () => {
              updateEventOptimistically(draggableId, source.droppableId as FlexibilityType);
            },
          },
        );
      }
    }
  };

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
      <Stack>
        {/* Flexibility Type Columns */}
        <Group align="flex-start" grow>
          {FLEXIBILITY_COLUMNS.map((column) => (
            <FlexibilityColumn
              key={column.id}
              {...column}
              events={flexibleEvents.filter((event) => event.flexibilityType === column.id)}
            />
          ))}
        </Group>

        <Stack gap="xs">
          <Group gap="xs">
            <IconReload size={20} />
            <Title order={2} size="h4">
              Recurring Meetings
            </Title>
          </Group>
          <Text size="sm" c="dimmed">
            Mark recurring meetings as movable or skippable for when Blockit can&apos;t find time on your calendar.
          </Text>
        </Stack>

        {/* Frequency Columns */}
        <Group align="flex-start" grow>
          {frequencyColumns.map((column) => (
            <Droppable key={column.id} droppableId={column.id}>
              {(provided) => (
                <Paper p="md" withBorder miw={{ base: '100%', sm: '150px' }}>
                  <Stack>
                    <Title order={4}>{column.title}</Title>
                    <Box {...provided.droppableProps} ref={provided.innerRef} mih="200px">
                      {column.events.length === 0 && (
                        <Text size="sm" c="dimmed">
                          No potentially flexible meetings to mark as flexible
                        </Text>
                      )}
                      {column.events.map((event, index) => (
                        <Draggable
                          key={event.recurringRemoteEventId}
                          draggableId={event.recurringRemoteEventId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <FlexibleEventCard event={event} provided={provided} snapshot={snapshot} />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  </Stack>
                </Paper>
              )}
            </Droppable>
          ))}
        </Group>
      </Stack>
    </DragDropContext>
  );
}
