import { Button, Group, Paper, Stack, Text } from '@mantine/core';
import { IconCalendar, IconCornerDownRightDouble, IconReplace } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { useMarkAsFlexible } from '../api/markAsFlexible';
import { useUnmarkAsFlexible } from '../api/unmarkAsFlexible';
import { FlexibilityType, FlexibleEvent } from '../types';
import { getRecurrenceText } from '../utils/formatRecurrence';

interface FlexibleEventItemProps {
  event: FlexibleEvent;
}

export function FlexibleEventItem({ event }: FlexibleEventItemProps) {
  const queryClient = useQueryClient();
  const { mutate: markAsFlexible } = useMarkAsFlexible({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flexible-events'] });
    },
  });
  const { mutate: unmarkAsFlexible } = useUnmarkAsFlexible({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flexible-events'] });
    },
  });

  const handleToggle = (flexibilityType: FlexibilityType | null) => {
    if (flexibilityType) {
      markAsFlexible({
        recurringRemoteEventId: event.recurringRemoteEventId,
        flexibilityType,
      });
    } else {
      unmarkAsFlexible({ recurringRemoteEventId: event.recurringRemoteEventId });
    }
  };

  return (
    <Paper p="md" withBorder>
      <Group justify="space-between">
        <Group>
          <IconCalendar size={20} />
          <div>
            <Text>{event.summary}</Text>
            <Text size="sm" c="dimmed">
              {getRecurrenceText(event)}
            </Text>
          </div>
        </Group>
        {!event.flexibilityType ? (
          <Stack gap="xs">
            <Button
              variant="outline"
              onClick={() => handleToggle(FlexibilityType.MOVABLE)}
              size="xs"
              leftSection={<IconReplace size={16} />}
            >
              Mark as movable
            </Button>
            <Button
              variant="outline"
              onClick={() => handleToggle(FlexibilityType.SKIPPABLE)}
              size="xs"
              leftSection={<IconCornerDownRightDouble size={16} />}
            >
              Mark as skippable
            </Button>
          </Stack>
        ) : (
          <Button variant="subtle" color="red" onClick={() => handleToggle(null)} size="xs">
            Remove flexibility
          </Button>
        )}
      </Group>
    </Paper>
  );
}
