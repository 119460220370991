import { useState } from 'react';

import { Button, Group, MultiSelect, SegmentedControl, Stack, Switch, Text, TextInput } from '@mantine/core';
import { IconActivity, IconArchive, IconFilter, IconSearch, IconX } from '@tabler/icons-react';

import { Contact, Organization } from '../types';

export interface ContactFilters {
  search?: string;
  organizationIds?: string[];
  locations?: string[];
  showArchived?: boolean;
  showInternal?: boolean;
}

interface ContactsFiltersProps {
  onFiltersChange: (filters: ContactFilters) => void;
  contacts: Contact[];
  organizations: Organization[];
}

export function ContactsFilters({ onFiltersChange, contacts, organizations }: ContactsFiltersProps) {
  const [search, setSearch] = useState('');
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [showArchived, setShowArchived] = useState(false);
  const [showInternal, setShowInternal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const uniqueLocations = Array.from(
    new Set(contacts?.map((contact) => contact.location?.split(',')[0]).filter((loc): loc is string => Boolean(loc))),
  );

  const handleReset = () => {
    setSearch('');
    setOrganizationIds([]);
    setLocations([]);
    setShowArchived(false);
    setShowInternal(false);
    onFiltersChange({});
    setIsExpanded(false);
  };

  const updateFilters = (updates: Partial<ContactFilters>) => {
    const newFilters = {
      search: search || undefined,
      organizationIds: organizationIds.length ? organizationIds : undefined,
      locations: locations.length ? locations : undefined,
      showArchived,
      showInternal,
      ...updates,
    };
    onFiltersChange(newFilters);
  };

  return (
    <Stack gap="sm">
      <Group gap="sm">
        <TextInput
          placeholder="Search"
          leftSection={<IconSearch size={16} />}
          value={search}
          onChange={(event) => {
            const newSearch = event.currentTarget.value;
            setSearch(newSearch);
            updateFilters({ search: newSearch || undefined });
          }}
          style={{ flex: 1 }}
        />
        {!isExpanded && (
          <Button variant="default" leftSection={<IconFilter size={16} />} onClick={() => setIsExpanded(!isExpanded)}>
            Filter
          </Button>
        )}
      </Group>
      {isExpanded && (
        <Stack gap="xs">
          <MultiSelect
            placeholder="Organizations"
            data={organizations.map((org) => ({ label: org.name, value: org.id }))}
            value={organizationIds}
            onChange={(value) => {
              setOrganizationIds(value);
              updateFilters({ organizationIds: value.length ? value : undefined });
            }}
            searchable
          />
          <MultiSelect
            placeholder="Locations"
            data={uniqueLocations}
            value={locations}
            onChange={(value) => {
              setLocations(value);
              updateFilters({ locations: value.length ? value : undefined });
            }}
            searchable
          />
          <SegmentedControl
            fullWidth
            data={[
              {
                label: (
                  <Group gap="3" justify="center">
                    <IconActivity size={16} />
                    <Text size="sm">Active</Text>
                  </Group>
                ),
                value: 'active',
              },
              {
                label: (
                  <Group gap="3" justify="center">
                    <IconArchive size={16} />
                    <Text size="sm">Archived</Text>
                  </Group>
                ),
                value: 'archived',
              },
            ]}
            value={showArchived ? 'archived' : 'active'}
            onChange={(value) => {
              const newShowArchived = value === 'archived';
              setShowArchived(newShowArchived);
              updateFilters({ showArchived: newShowArchived });
            }}
          />
          <Switch
            label="Show internal contacts"
            checked={showInternal}
            onChange={(event) => {
              const newShowInternal = event.currentTarget.checked;
              setShowInternal(newShowInternal);
              updateFilters({ showInternal: newShowInternal });
            }}
          />
          <Button variant="subtle" color="red" leftSection={<IconX size={16} />} onClick={handleReset}>
            Reset
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
