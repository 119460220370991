import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { Contact } from '../types';

export const activateContact = async (contactId: string): Promise<Contact> => {
  const { data } = await axios.post<Contact>(`/contacts/${contactId}/activate`, { contactId });
  return data;
};

export const useActivateContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: activateContact,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contacts'] });
      notifications.show({
        title: 'Success',
        message: 'Contact activated successfully',
        color: 'green',
      });
    },
  });
};
