import {
  IconAdjustments,
  IconAdjustmentsFilled,
  IconArrowLoopRight,
  IconHome,
  IconHomeFilled,
  IconLocation,
  IconLocationFilled,
  IconPrompt,
  IconProps,
  IconPuzzle,
  IconPuzzleFilled,
  IconTestPipe,
  IconUsers,
} from '@tabler/icons-react';

import { FilledSquaresIcon } from '@components/FilledSquaresIcon';

import { PRIVATE_ROUTES, SUB_SECTIONS } from './enums';

export interface RouteDetails {
  title: string;
  icon: React.ComponentType<IconProps>;
  activeIcon?: React.ComponentType<IconProps>;
  hidden?: boolean;
  subSection?: SUB_SECTIONS;
  path: PRIVATE_ROUTES;
}

// Ordering determines how the routes are displayed in the sidebar
export const ROUTE_DETAILS: RouteDetails[] = [
  {
    title: 'Feed',
    icon: IconHome,
    activeIcon: IconHomeFilled,
    path: PRIVATE_ROUTES.FEED,
  },
  {
    title: 'Contacts',
    icon: IconUsers,
    path: PRIVATE_ROUTES.CONTACTS,
  },
  {
    title: 'Integrations',
    icon: IconPuzzle,
    activeIcon: IconPuzzleFilled,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.INTEGRATIONS,
  },
  {
    title: 'Preferences',
    icon: IconAdjustments,
    activeIcon: IconAdjustmentsFilled,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.PREFERENCES,
  },
  {
    title: 'Availability',
    icon: FilledSquaresIcon,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.AVAILABILITY,
  },

  {
    title: 'Beta',
    icon: IconTestPipe,
    hidden: true,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.BETA,
  },
  {
    title: 'Places',
    icon: IconLocation,
    activeIcon: IconLocationFilled,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.PLACES,
  },
  {
    title: 'Flexibility',
    icon: IconArrowLoopRight,
    subSection: SUB_SECTIONS.SETTINGS,
    path: PRIVATE_ROUTES.FLEXIBILITY,
  },
  {
    title: 'Codewords',
    icon: IconPrompt,
    subSection: SUB_SECTIONS.WORKFLOWS,
    path: PRIVATE_ROUTES.CODEWORDS,
  },
];

export const ROUTES_TO_ROUTE_DETAILS: Record<PRIVATE_ROUTES, RouteDetails> = ROUTE_DETAILS.reduce(
  (acc, route) => {
    acc[route.path] = route;
    return acc;
  },
  {} as Record<PRIVATE_ROUTES, RouteDetails>,
);
