import { useEffect, useState } from 'react';

import { Stack } from '@mantine/core';

import { DayOfWeek, TimeOfDay } from '@/types';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings, WorkingHour, WorkingHourSettings } from '../types';
import { constrainAvailabilityToWorkingHours } from '../utils/constrainAvailabilityToWorkingHours';
import { addToTimeOfDay, convertTimeOfDayToDateTime, getDiffInMinutesForTimeOfDays } from '../utils/timeOfDay';

import { WorkingHoursForDay } from './WorkingHoursForDay';

type WorkingHoursErrors = {
  [key in DayOfWeek]?: string | null;
};

interface WorkingHoursFormProps {
  preferences: PreferenceSettings;
  onUpdate?: (workingHours: WorkingHourSettings | null) => void;
}

export const WorkingHoursForm = ({ preferences, onUpdate }: WorkingHoursFormProps) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: (data: PreferenceSettings) => {
      onUpdate?.(data.workingHours);
    },
  });

  const [workingHours, setWorkingHours] = useState<WorkingHourSettings | undefined>();
  const [errors, setErrors] = useState<WorkingHoursErrors>({});

  useEffect(() => {
    if (!preferences || workingHours) return;
    setWorkingHours(preferences.workingHours || {});
  }, [preferences, workingHours]);

  const updateWorkingHoursAndAvailability = (newWorkingHours: WorkingHourSettings) => {
    if (preferences && preferences.availability) {
      updatePreferences({
        ...preferences,
        workingHours: newWorkingHours,
        availability: constrainAvailabilityToWorkingHours(preferences.availability, newWorkingHours),
      });
    }
  };

  const handleWorkingHourChange = (day: DayOfWeek, index: number, start: string, end: string) => {
    if (!workingHours) return;

    const newWorkingHours = { ...workingHours };
    if (!newWorkingHours[day]) {
      newWorkingHours[day] = [];
    }
    const newWorkingHoursForDay = [...(newWorkingHours[day] || [])];
    newWorkingHours[day] = newWorkingHoursForDay;
    if (!newWorkingHoursForDay) {
      return;
    }
    const currentWorkingHours = newWorkingHoursForDay[index];

    if (!currentWorkingHours) {
      return;
    }
    newWorkingHoursForDay[index] = [start as TimeOfDay, end as TimeOfDay];

    setWorkingHours(newWorkingHours);

    const newErrors: WorkingHoursErrors = {};

    const isValidWorkingHours = Object.entries(newWorkingHours).every(([day, dayWorkingHours]) => {
      if (!dayWorkingHours || dayWorkingHours.length === 0) return true;

      return dayWorkingHours.every(([start, end], index) => {
        if (!start || !end) return false;

        const parsedStart = convertTimeOfDayToDateTime(start);
        const parsedEnd = convertTimeOfDayToDateTime(end);

        if (!parsedStart.isValid || !parsedEnd.isValid) return false;
        if (getDiffInMinutesForTimeOfDays(end, start) <= 0) {
          newErrors[day as DayOfWeek] = `Slot ${index + 1}: Start time must be before end time`;
          return false;
        }
        return true;
      });
    });

    setErrors(newErrors);

    if (isValidWorkingHours) {
      updateWorkingHoursAndAvailability({ ...newWorkingHours });
    }
  };

  const setDefaultWorkingHours = (day: DayOfWeek) => {
    const newWorkingHours = {
      ...workingHours,
      [day]: [['09:00', '17:00']],
    };
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  const removeWorkingHours = (day: DayOfWeek, index: number) => {
    if (!workingHours || !workingHours[day]) return;

    const newWorkingHours = { ...workingHours };

    newWorkingHours[day] = newWorkingHours[day] ? newWorkingHours[day]!.filter((_, i) => i !== index) : [];

    const newWorkingHoursForDay = [...(newWorkingHours[day] || [])];
    if (newWorkingHoursForDay && newWorkingHoursForDay.length === 0) {
      delete newWorkingHours[day];
    }
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  const addWorkingHoursSlot = (day: DayOfWeek) => {
    if (!workingHours) return;

    const newWorkingHours = { ...workingHours };
    if (!newWorkingHours[day]) {
      newWorkingHours[day] = [];
    }
    const newWorkingHoursForDay = [...(newWorkingHours[day] || [])];
    newWorkingHours[day] = newWorkingHoursForDay;
    if (!newWorkingHoursForDay) {
      return;
    }
    const previousSlot =
      newWorkingHoursForDay.length > 0 ? newWorkingHoursForDay[newWorkingHoursForDay.length - 1] : null;
    let newWorkingHourSlot: WorkingHour = previousSlot
      ? [addToTimeOfDay(previousSlot[1], 1), addToTimeOfDay(previousSlot[1], 2)]
      : ['09:00', '14:00'];

    if (getDiffInMinutesForTimeOfDays(newWorkingHourSlot[1], newWorkingHourSlot[0]) <= 0) {
      newWorkingHourSlot = ['09:00', '14:00'];
    }
    newWorkingHoursForDay.push(newWorkingHourSlot);
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  const handleCopyWorkingHours = (currentDay: DayOfWeek, daysToCopy: DayOfWeek[]) => {
    if (!workingHours) {
      return;
    }
    const workingHourToCopy = workingHours[currentDay];
    const newWorkingHours = { ...workingHours };
    daysToCopy.forEach((day) => (newWorkingHours[day] = workingHourToCopy));
    setWorkingHours(newWorkingHours);
    updateWorkingHoursAndAvailability(newWorkingHours);
  };

  if (!workingHours) return null;

  return (
    <Stack gap="xs">
      {Object.values(DayOfWeek).map((day) => (
        <WorkingHoursForDay
          key={day}
          day={day}
          workHours={workingHours[day] || []}
          errors={errors[day]}
          onAddWorkingHours={setDefaultWorkingHours}
          onWorkingHourChange={handleWorkingHourChange}
          onRemoveWorkingHours={removeWorkingHours}
          onAddWorkingHoursSlot={addWorkingHoursSlot}
          onCopyWorkingHours={handleCopyWorkingHours}
        />
      ))}
    </Stack>
  );
};
