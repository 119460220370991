import { Group, Stack, Text, Title } from '@mantine/core';
import { IconReload } from '@tabler/icons-react';

import { FlexibleEventsList } from './FlexibleEventsList';

export function AllPossibleEventsSection() {
  return (
    <Stack gap="md">
      <Group gap="xs">
        <IconReload size={24} />
        <Title order={2} size="h3">
          Recurring Meetings
        </Title>
      </Group>
      <Text size="sm" c="dimmed">
        Mark recurring meetings as movable or skippable for when Blockit can&apos;t find time on your calendar.
      </Text>
      <FlexibleEventsList flexibilityType={null} />
    </Stack>
  );
}
