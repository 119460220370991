import { Button, Tooltip } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { DateTime } from 'luxon';

import { useBuildMeetingGraph } from '../api/buildMeetingGraph';

export const SyncFromCalendar = () => {
  const timeWindow = {
    start: DateTime.now().minus({ months: 12 }),
    end: DateTime.now().plus({ months: 2 }),
  };
  const { mutate: buildGraph, isPending } = useBuildMeetingGraph(timeWindow);

  return (
    <Tooltip
      withArrow
      w="220"
      multiline
      position="bottom"
      // eslint-disable-next-line max-len
      label="We will look at all of your meetings from the past 12 months and upcoming 2 months to find contacts."
    >
      <Button
        variant="outline"
        leftSection={<IconRefresh size={16} />}
        onClick={() => buildGraph()}
        loading={isPending}
        fullWidth
      >
        Sync from calendar
      </Button>
    </Tooltip>
  );
};
