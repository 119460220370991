import { Group, Stack, Text, Title } from '@mantine/core';
import { IconReplace } from '@tabler/icons-react';

import { FlexibilityType } from '../types';

import { FlexibleEventsList } from './FlexibleEventsList';

export function MovableEventsSection() {
  return (
    <Stack gap="md">
      <Group gap="xs">
        <IconReplace size={24} />
        <Title order={2} size="h3">
          Movable
        </Title>
      </Group>
      <Text size="sm" c="dimmed">
        If Blockit can&apos;t find time on your calendar, it will <u>auto-reschedule</u> these meetings.
      </Text>
      <FlexibleEventsList flexibilityType={FlexibilityType.MOVABLE} />
    </Stack>
  );
}
