import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { BASE_API_PATH } from '../constants';
import { FlexibleEvent } from '../types';

interface UnmarkAsFlexibleParams {
  recurringRemoteEventId: string;
}

const unmarkAsFlexible = async ({ recurringRemoteEventId }: UnmarkAsFlexibleParams): Promise<FlexibleEvent> => {
  const { data } = await axios.delete<FlexibleEvent>(`${BASE_API_PATH}/${recurringRemoteEventId}/unmark`);
  return data;
};

export const useUnmarkAsFlexible = (options?: UseMutationOptions<FlexibleEvent, unknown, UnmarkAsFlexibleParams>) => {
  return useMutation({
    mutationFn: unmarkAsFlexible,
    ...options,
  });
};
