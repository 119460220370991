import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { Contact } from '../types';

export const archiveContact = async ({
  contactId,
  emails,
}: {
  contactId?: string;
  emails?: string[];
}): Promise<Contact> => {
  const { data } = await axios.post<Contact>(`/contacts/archive`, { contactId, emails });
  return data;
};

export const useArchiveContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveContact,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contacts'] });
      notifications.show({
        title: 'Success',
        message: 'Contact archived successfully',
        color: 'green',
      });
    },
  });
};
