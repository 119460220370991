import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { BASE_API_PATH } from '../constants';
import { FlexibilityType, FlexibleEvent } from '../types';

interface MarkAsFlexibleParams {
  recurringRemoteEventId: string;
  flexibilityType: FlexibilityType;
}

const markAsFlexible = async ({
  recurringRemoteEventId,
  flexibilityType,
}: MarkAsFlexibleParams): Promise<FlexibleEvent> => {
  const { data } = await axios.post<FlexibleEvent>(`${BASE_API_PATH}/${recurringRemoteEventId}/mark`, {
    flexibilityType,
  });
  return data;
};

export const useMarkAsFlexible = (options?: UseMutationOptions<FlexibleEvent, unknown, MarkAsFlexibleParams>) => {
  return useMutation({
    mutationFn: markAsFlexible,
    ...options,
  });
};
