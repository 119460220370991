import { useMemo } from 'react';

import { ContactFilters } from '../components/ContactsFilters';
import { Contact, ContactState, ContactTableColumn, Organization, SortState } from '../types';

const useFilteredContacts = (
  contacts: Contact[],
  filters: ContactFilters,
  sort: SortState,
  organizationsById: Record<string, Organization>,
) => {
  return useMemo(() => {
    let filtered = contacts.filter((contact) => {
      const matchesSearch = !filters.search
        ? true
        : [
            contact.firstName,
            contact.lastName,
            contact.currentTitle,
            contact.emails.join(' '),
            organizationsById[contact.currentOrganizationId]?.name,
            contact.location,
          ]
            .join(' ')
            .toLowerCase()
            .includes(filters.search.toLowerCase());

      const matchesOrganizations = !filters.organizationIds?.length
        ? true
        : (contact.currentOrganizationId && filters.organizationIds.includes(contact.currentOrganizationId)) ||
          contact.pastOrganizationIds?.some((orgId) => filters.organizationIds?.includes(orgId));

      const matchesLocations = !filters.locations?.length
        ? true
        : contact.location && filters.locations.includes(contact.location.split(',')[0]);

      const matchesArchived = filters.showArchived
        ? contact.state === ContactState.ARCHIVED
        : contact.state === ContactState.ACTIVE;

      const matchesInternal = filters.showInternal ? true : !contact.isInternal;

      return matchesSearch && matchesOrganizations && matchesLocations && matchesArchived && matchesInternal;
    });

    if (sort.field && sort.direction) {
      filtered = [...filtered].sort((a, b) => {
        let compareA: string | number | undefined;
        let compareB: string | number | undefined;

        switch (sort.field) {
          case ContactTableColumn.NAME:
            compareA = a.firstName ? `${a.firstName} ${a.lastName}` : a.emails[0];
            compareB = b.firstName ? `${b.firstName} ${b.lastName}` : b.emails[0];
            break;
          case ContactTableColumn.ORGANIZATION:
            compareA = organizationsById[a.currentOrganizationId]?.name;
            compareB = organizationsById[b.currentOrganizationId]?.name;
            break;
          case ContactTableColumn.TITLE:
            compareA = a.currentTitle;
            compareB = b.currentTitle;
            break;
          case ContactTableColumn.LOCATION:
            compareA = a.location?.split(',')[0];
            compareB = b.location?.split(',')[0];
            break;
          case ContactTableColumn.LATEST:
            compareA = a.latest?.toMillis() as number;
            compareB = b.latest?.toMillis() as number;
            break;
          case ContactTableColumn.MEETING_COUNT:
            compareA = a.meetingCount;
            compareB = b.meetingCount;
            break;
        }

        if (compareA === undefined || compareA === null) return 1;
        if (compareB === undefined || compareB === null) return -1;

        const multiplier = sort.direction === 'asc' ? 1 : -1;
        return compareA < compareB ? -1 * multiplier : multiplier;
      });
    }

    return filtered;
  }, [contacts, filters, sort, organizationsById]);
};

export default useFilteredContacts;
