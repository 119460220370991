import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Box, Center, Group, Paper, Stack, Text, Title } from '@mantine/core';

import { FlexibilityType, FlexibleEvent } from '../types';

import { FlexibleEventCard } from './FlexibleEventCard';

export interface FlexibilityColumnProps {
  id: FlexibilityType;
  title: string;
  description: React.ReactNode;
  Icon: React.ElementType;
  events: FlexibleEvent[];
}

export const FlexibilityColumn = ({ id, title, description, Icon, events }: FlexibilityColumnProps) => {
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <Paper p="md" withBorder mih="400px" h="100%" display="flex" miw={{ base: '100%', sm: '300px' }}>
          <Stack gap="xs" style={{ flex: 1 }}>
            <Group gap="xs">
              <Icon size={20} />
              <Title order={4}>{title}</Title>
            </Group>
            <Text size="sm" c="dimmed">
              {description}
            </Text>
            <Box {...provided.droppableProps} ref={provided.innerRef} h="100%" style={{ flex: 1 }}>
              {events.length === 0 && (
                <Center mih="200px">
                  <Text size="sm" c="dimmed">
                    Drag and drop meetings here
                  </Text>
                </Center>
              )}
              {events.map((event, index) => (
                <Draggable key={event.recurringRemoteEventId} draggableId={event.recurringRemoteEventId} index={index}>
                  {(provided, snapshot) => <FlexibleEventCard event={event} provided={provided} snapshot={snapshot} />}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          </Stack>
        </Paper>
      )}
    </Droppable>
  );
};
