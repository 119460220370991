import { ActionIcon, Button, Group, Modal, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconArchive } from '@tabler/icons-react';

import { Contact } from '@features/contacts/types';

import { useArchiveContact } from '../api/archiveContact';

interface ArchiveContactButtonProps {
  contact: Contact;
}

export function ArchiveContactButton({ contact }: ArchiveContactButtonProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const { mutate: archiveContact, isPending } = useArchiveContact();

  const handleArchive = () => {
    archiveContact(
      { contactId: contact.id, emails: contact.emails },
      {
        onSuccess: close,
      },
    );
  };

  const contactName = contact.firstName ? `${contact.firstName} ${contact.lastName ?? ''}`.trim() : contact.emails[0];
  return (
    <>
      <ActionIcon variant="subtle" color="red" onClick={open} title="Archive contact">
        <IconArchive size={16} />
      </ActionIcon>

      <Modal opened={opened} onClose={close} title="Archive Contact" size="sm">
        <Stack w="100%">
          <Text size="sm">Are you sure you want to archive {contactName}?</Text>
          <Group gap="xs" justify="flex-end">
            <Button variant="light" onClick={close} disabled={isPending}>
              Cancel
            </Button>
            <Button color="red" onClick={handleArchive} loading={isPending}>
              Archive Contact
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
}
