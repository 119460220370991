import { Stack, Tabs, Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { AllPossibleEventsSection } from './AllPossibleEventsSection';
import { FlexibleEventsKanban } from './FlexibleEventsKanban';
import { MovableEventsSection } from './MovableEventsSection';
import { SkippableEventsSection } from './SkippableEventsSection';

export function FlexibilityPage() {
  return (
    <SideContent
      sideContent={
        <Text c="dimmed">
          Mark events as flexible so that Blockit can automatically move or schedule over them when necessary.
        </Text>
      }
      showFullWidth
    >
      <Tabs defaultValue="kanban">
        <Tabs.List>
          <Tabs.Tab value="kanban">Kanban View</Tabs.Tab>
          <Tabs.Tab value="list">List View</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="kanban" pt="xl">
          <FlexibleEventsKanban />
        </Tabs.Panel>

        <Tabs.Panel value="list" pt="xl">
          <Stack gap="xl">
            <MovableEventsSection />
            <SkippableEventsSection />
            <AllPossibleEventsSection />
          </Stack>
        </Tabs.Panel>
      </Tabs>
    </SideContent>
  );
}
