import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TimeWindow } from '@/types';
import { axios } from '@lib/axios';

const buildMeetingGraph = async (range: TimeWindow): Promise<void> => {
  await axios.post('/meeting-graph/build', {
    start: range.start.toUTC().toISO(),
    end: range.end.toUTC().toISO(),
  });
};

export const useBuildMeetingGraph = (range: TimeWindow) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => buildMeetingGraph(range),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contacts'] });
      notifications.show({
        title: 'Success',
        message: 'Meeting graph built successfully',
        color: 'green',
      });
    },
  });
};
