import type { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';
import { Box, Card, Group, Text } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';

import { FlexibleEvent } from '../types';
import { getRecurrenceText } from '../utils/formatRecurrence';

import classes from './FlexibleEventCard.module.css';

interface FlexibleEventCardProps {
  event: FlexibleEvent;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

export const FlexibleEventCard = ({ event, provided, snapshot }: FlexibleEventCardProps) => {
  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      withBorder
      mb="sm"
      style={{
        ...provided.draggableProps.style,
        borderColor: snapshot.isDragging ? 'var(--mantine-color-active)' : undefined,
      }}
      className={classes.card}
      shadow="xs"
    >
      <Group gap="xs">
        <IconCalendar size={20} />
        <Box>
          <Text>{event.summary}</Text>
          <Text size="sm" c="dimmed">
            {getRecurrenceText(event)}
          </Text>
        </Box>
      </Group>
    </Card>
  );
};
