import { ActionIcon, Group } from '@mantine/core';
import { IconActivity, IconEdit } from '@tabler/icons-react';

import { useActivateContact } from '@features/contacts/api/activateContact';

import { Contact, ContactState } from '../../types';
import { ArchiveContactButton } from '../ArchiveContactButton';

interface ActionsContentProps {
  contact: Contact;
  onEdit?: () => void;
}

export const ActionsContent = ({ contact, onEdit }: ActionsContentProps) => {
  const activateContact = useActivateContact();

  const handleActivate = () => {
    if (contact.id) {
      activateContact.mutate(contact.id);
    }
  };

  return (
    <Group gap={0} wrap="nowrap">
      <ActionIcon variant="subtle" onClick={onEdit} title="Edit contact">
        <IconEdit size={16} />
      </ActionIcon>
      {contact.state === ContactState.ACTIVE ? (
        <ArchiveContactButton contact={contact} />
      ) : (
        contact.id && (
          <ActionIcon
            variant="subtle"
            onClick={handleActivate}
            title="Activate contact"
            loading={activateContact.isPending}
          >
            <IconActivity size={16} />
          </ActionIcon>
        )
      )}
    </Group>
  );
};
