import { Group, Stack, Text, Title } from '@mantine/core';
import { IconCornerDownRightDouble } from '@tabler/icons-react';

import { FlexibilityType } from '../types';

import { FlexibleEventsList } from './FlexibleEventsList';

export function SkippableEventsSection() {
  return (
    <Stack gap="md">
      <Group gap="xs">
        <IconCornerDownRightDouble size={24} />
        <Title order={2} size="h3">
          Skippable
        </Title>
      </Group>
      <Text size="sm" c="dimmed">
        If Blockit can&apos;t find time on your calendar, it will schedule over these meetings.
      </Text>
      <FlexibleEventsList flexibilityType={FlexibilityType.SKIPPABLE} />
    </Stack>
  );
}
