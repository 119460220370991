import { useMemo, useState } from 'react';

import { Table, Text } from '@mantine/core';

import useFilteredContacts from '../hooks/useFilteredContacts';
import { Contact, ContactTableColumn, Organization, SortField, SortState } from '../types';

import { ContactFilters } from './ContactsFilters';
import classes from './ContactsTable.module.css';
import { ContactTableRow } from './ContactTableRow';
import { TableHeaderColumn } from './TableHeaderColumn';

interface ContactsTableProps {
  contacts: Contact[];
  organizations: Organization[];
  filters: ContactFilters;
  sort: SortState;
  onSort: (field: SortField) => void;
}

type ColumnWidth = Record<ContactTableColumn, number>;

const COLUMNS: { [key in ContactTableColumn]: { width: number; title: string; sortable: boolean } } = {
  [ContactTableColumn.NAME]: { width: 200, title: 'Name', sortable: true },
  [ContactTableColumn.ORGANIZATION]: {
    width: 200,
    title: 'Organization',
    sortable: true,
  },
  [ContactTableColumn.TITLE]: { width: 150, title: 'Title', sortable: true },
  [ContactTableColumn.EMAILS]: { width: 200, title: 'Emails', sortable: false },
  [ContactTableColumn.INFO]: { width: 100, title: 'Info', sortable: false },
  [ContactTableColumn.LATEST]: { width: 150, title: 'Latest', sortable: true },
  [ContactTableColumn.LOCATION]: { width: 150, title: 'Location', sortable: true },
  [ContactTableColumn.MEETING_COUNT]: { width: 120, title: 'Meetings', sortable: true },
  [ContactTableColumn.ACTIONS]: { width: 100, title: 'Actions', sortable: false },
};

export const ContactsTable = ({ contacts, organizations, filters, sort, onSort }: ContactsTableProps) => {
  const [columnWidths, setColumnWidths] = useState<ColumnWidth>(
    Object.fromEntries(Object.entries(COLUMNS).map(([key, col]) => [key, col.width])) as ColumnWidth,
  );

  const handleResize = (column: string, width: number) => {
    setColumnWidths((prev) => ({
      ...prev,
      [column]: width,
    }));
  };

  const organizationsById = useMemo(() => {
    return organizations.reduce(
      (acc, org) => {
        acc[org.id] = org;
        return acc;
      },
      {} as Record<string, Organization>,
    );
  }, [organizations]);

  const filteredContacts = useFilteredContacts(contacts, filters, sort, organizationsById);

  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableWrapper}>
        <Table highlightOnHover style={{ tableLayout: 'fixed' }} verticalSpacing="0" w="100%">
          <Table.Thead>
            <Table.Tr className={classes.stickyHeader}>
              {Object.entries(COLUMNS).map(([key, column], index) => (
                <TableHeaderColumn
                  key={key}
                  column={{ ...column, key: key as ContactTableColumn, width: columnWidths[key as ContactTableColumn] }}
                  sort={sort}
                  onSort={onSort}
                  onResize={handleResize}
                  isSticky={index === 0}
                />
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredContacts.map((contact) => (
              <ContactTableRow
                key={contact.emails.join('')}
                contact={contact}
                organizationsById={organizationsById}
                filters={filters}
                columnWidths={columnWidths}
              />
            ))}
          </Table.Tbody>
        </Table>
      </div>
      <Text size="md" c="dimmed" ta="center" w="100%">
        {filteredContacts.length} contact{filteredContacts.length === 1 ? '' : 's'} found
      </Text>
    </div>
  );
};
